import React from 'react';
import { getLanguageNativeName, getLanguageName } from '../util/languagecode.js';
import { capitalizeFirst } from '../util/stringutils.js';

export default function DataSheets({sds}) {
     const sheets = sds.contents.map(file => {
        var start = file.name.indexOf('[') + 1;
        var end = file.name.indexOf(']');
        var langcode = file.name.slice(start, end).toLowerCase();
        var language = `${capitalizeFirst(getLanguageNativeName(langcode))} (${getLanguageName(langcode)})`
        var url = `/files/${sds.name}/${file.name}`;

        return (
            <tr key={file.name}>
                <td className='datasheets-language'>{language}</td>
                <td><a className='datasheets-file' href={url}>{file.name}</a></td>
                <td><a href={url}><img src="/images/pdf.png" alt="download pdf" /></a></td>
            </tr>
        )
    }
    )
    return (
        <section className='datasheets'>
            <h1 className='datasheets-title'>Results for {sds.name}</h1>
            <table>
                <tbody>
                    {sheets}
                </tbody>
            </table>
        </section>
    );
}