import React from 'react';

export default function SearchBar({searchKey, searchOnChange}) {
  
  return (
    <div className="searchbar">
      <input
        id="search_key"
        type="search"
        name="search_key"
        size="50"
        placeholder="Search for a safety datasheet (min. 3 characters)"
        onChange={searchOnChange}
        value={searchKey} />
      <img className="search-icon" src="/images/search.svg" alt="Looking glass" />      
    </div>
  );
}