import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import SearchBar from './SearchBar';
import Header from './Header';
import SearchResults from './SearchResults';
import Bom from './Bom';
import DataSheets from './DataSheets';
import List from './List';
import Footer from './Footer';

export default function Main() {
  const { searchParm } = useParams();
  const [searchKey, setSearchKey] = useState(searchParm ? searchParm.toUpperCase() : "");
  const [kits, setKits] = useState([]);
  const [sdsFiles, setSdsFiles] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [singleResult, setSingleResult] = useState({ resultType: "none" });

  function searchOnChange(event) {
    setSearchKey(event.target.value.toUpperCase());
  }

  useEffect(() => {
    findSearchResults(searchKey);
  }
    , [searchKey, kits, sdsFiles])

  function findSearchResults(pattern) {
    setSingleResult({ resultType: "none" });
    setSearchResults([]);
    pattern = pattern.trim().toUpperCase();
    if (pattern === "LISTKITS") {
      setSingleResult({ resultType: "LISTKITS" });
    } else if (pattern === "LISTSHEETS") {
      setSingleResult({ resultType: "LISTSHEETS" });
    } else if (pattern.length >= 3) {
      var foundKits = kits.filter(kit => {
        var kitName = kit.name.toUpperCase();
        if (kitName === pattern) setSingleResult({ resultType: "KIT", ...kit });
        return kitName.includes(pattern);
      });
      var foundFiles = sdsFiles.filter(file => {
        var fileName = file.name.toUpperCase();
        if (fileName === pattern) setSingleResult({ resultType: "SDS", ...file });
        return file.type === "directory" && fileName.includes(pattern);
      });
      setSearchResults(foundKits.concat(foundFiles));
    }
  }

  async function fetchKits() {
    await fetch("/data/kits.json", {
      headers:
      {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    })
      .then(res => res.json())
      .then(data => {
        setKits(data);
      })
  }

  async function fetchSdsFiles() {
    await fetch("/data/sdsfiles.json", {
      headers:
      {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    })
      .then(res => res.json())
      .then(data => {
        setSdsFiles(data);
      })
  }

  useEffect(() => {
    fetchKits();
    fetchSdsFiles();
  }, []);

  return (
    <>
      <Header />
      <SearchBar searchKey={searchKey} searchOnChange={searchOnChange} />
      <div className="divider"></div>
      {singleResult.resultType === "none" && <SearchResults searchKey={searchKey} searchResults={searchResults} />}
      {singleResult.resultType === "KIT" && <Bom kit={singleResult} />}
      {singleResult.resultType === "SDS" && <DataSheets sds={singleResult} />}
      {singleResult.resultType === "LISTKITS" && <List list={kits} />}
      {singleResult.resultType === "LISTSHEETS" && <List list={sdsFiles} />}
      <Footer />
    </>
  );
}
