import React from 'react';

export default function Bom({ kit }) {

  return (
    <section className='bom'>      
      <h1 className='bom-title'>{`Bill of material for ${kit.name}:`}</h1>
      <ul>
        {kit.bom.map((r) => <li key={r}><a href={`${r}`}>{r}</a></li>)}
      </ul>
    </section>
  );
}