import React from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Main from './Main.js'
import './sds.css';

export default function App() {
  return (

    <Router>
      <Routes>
        <Route path='/' element={<Main />}>
          <Route path=':searchParm' element={<Main />} />          
        </Route>
        <Route path='*' element={<Main />} />
      </Routes>
    </Router>
  );
}
// import React from 'react';

// function ReadFile() {
//     const [employees, setEmployee] = useState([]);

//     useEffect(() => {
//         getAPI("./employee.json")
//     }, []);

//     const getAPI = (data) => {
//         console.log(data)
//         fetch(data, {
//             headers:
//             {
//                 'Content-Type': 'application/json',
//                 'Accept': 'application/json'
//             }
//         })
//             .then(res => res.json())
//             .then(data => {
//                 console.log(data)
//                 setEmployee(data);
//             })
//     }



//     return (<div >
//         <ul>
//             {employees.map((record, i) => <li key={i}>{record.id} - {record.firstName} {record.firstName}</li>)}
//         </ul>
//     </div>
//     );

// }

// export default ReadFile;
