import React from 'react';

export default function SearchResults({ searchKey, searchResults }) {
  var title = "";
  if (searchKey.length < 3) {
    title = "Please use search to find a splicing kit or safety datasheet."
  } else if (searchResults.length === 0) {
    title = `Nothing found for ${searchKey}!`
  } else {
    title = `SearchResults for ${searchKey}:`
  }

  return (
    <section className='searchresults'>
      <h1 className='searchresults-title'>{title}</h1>
      <ul>
        {searchResults.map((r) => <li key={r.name}><a href={`${r.name}`}>{r.name} {r.bom !== undefined ? "(splicing kit)" : ""}</a></li>)}
      </ul>
    </section>
  );
}