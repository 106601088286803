import React from 'react';

export default function List({ list }) {

  return (
    <section className='list'>
      <ul>
        {list.map((r) =>
          <li key={r.name}>
            <a href={`${r.name}`}>{r.name}</a>
            {r.bom !== undefined &&
              <ul>
                {r.bom.map((b) =>
                  <li key={b}>
                    <a href={`${b}`}>{b}</a>
                  </li>
                )}
              </ul>}
            {r.contents !== undefined &&
              <ul>
                {r.contents.map((f) =>
                  <li key={f.name}>{f.name}</li>
                )}
              </ul>}
            
          </li>
        )}
      </ul>
    </section>
  )
}