import React from 'react';

export default function Header() {

  return (
    <header className="header">


      <a className="header-logo" href="/">
        <img src="/images/logo.svg" alt="Dunlop Conveyor Belting" />
      </a>

      <div className="department-title">Health and Safety Department</div>

    </header>
  );
}
// <div className="header-logo"> </div>